<template>
  <section class="bando-head">
    <h1>Attribution des FSE aux zones de l'établissement</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">
        Attribution des FSE aux zones de l'établissement
        <span class="yellow">LABEGE PDC1</span>
      </h2>

      <div class="row d-none d-lg-flex">
        <div class="col-md-5">
          <h3>
            <img src="@/assets/img/icon-zones.png" alt="Zones de vacations" />
            Zones de vacations
          </h3>
        </div>

        <div class="col col-md-7">
          <h3>
            <img src="@/assets/img/icon-fse.png" alt="FSE de l'établissement" />
            FSE de l'établissement
          </h3>
        </div>
      </div>

      <div
        class="row"
        v-for="zoneVacation in zonesVacations"
        :key="zoneVacation"
      >
        <div class="col-md-5">
          <h3 class="d-block d-lg-none">
            <img src="@/assets/img/icon-zones.png" alt="Zone de vacations" />
            Zone de vacations
          </h3>
          <div class="zone-vacations">
            <h4>{{ zoneVacation.nom }}</h4>
            <div class="villes">
              <div v-for="commune in zoneVacation.communes" :key="commune">
                {{ commune.cp }} {{ commune.nom }}
              </div>
            </div>
          </div>
        </div>

        <div class="col col-md-7">
          <div class="row">
            <div class="col-md-6">
              <h4>FSE rattachés</h4>
              <div class="d-flex" v-for="fse in zoneVacation.fses" :key="fse">
                <div
                  class="fse"
                  :style="'background: ' + fse.couleur + ';'"
                  :data-color="fse.couleur"
                >
                  {{ fse.prenom }} {{ fse.nom }}
                </div>
                &nbsp;
                <button
                  type="button"
                  class="btn btn-add"
                  @click="removeFse(zoneVacation, fse)"
                >
                  <i class="fal fa-minus"></i>
                </button>
              </div>
            </div>
            <div class="col-md-6">
              <h4>FSE non rattachés</h4>
              <div class="d-flex" v-for="fse in fses" :key="fse">
                <template v-if="!hasFse(fse.id, zoneVacation.fses)">
                  <div
                    class="fse"
                    :style="'background: ' + fse.couleur + ';'"
                    :data-color="fse.couleur"
                  >
                    {{ fse.prenom }} {{ fse.nom }}
                  </div>
                  &nbsp;
                  <button
                    type="button"
                    class="btn btn-add"
                    @click="addFse(zoneVacation, fse)"
                  >
                    <i class="fal fa-plus"></i>
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button
          type="submit"
          class="btn btn-primary mt-5"
          @click="saveZonesVacations"
        >
          Valider
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { DateTime, Settings } from "luxon";

Settings.defaultLocale = "fr";

export default {
  name: "AttributionFSE",
  components: {},
  data() {
    return {
      fses: [],
      zonesVacations: []
    };
  },
  created() {
    axios
      .all([
        axios.get(process.env.VUE_APP_API + "/fse/list"),
        axios.get(process.env.VUE_APP_API + "/zone-vacation/list")
      ])
      .then(
        axios.spread((...responses) => {
          this.fses = responses[0].data;
          this.zonesVacations = responses[1].data;
        })
      )
      .catch(() => {
        this.$swal.fire("Une erreur s'est produite.");
      });
  },
  methods: {
    hasFse: function(id, fses) {
      return fses ? fses.find(f => f.id === id) : true;
    },
    addFse: function(zv, fse) {
      zv.fses.push(fse);
    },
    removeFse: function(zv, fse) {
      console.log(fse);
      const f = this.fses.find(fs => fs.id == fse.id);
      let hasVac = false;
      f.vacations.forEach(v => {
        if (
          v.zone.id == zv.id &&
          DateTime.now() < DateTime.fromISO(v.date_end)
        ) {
          hasVac = true;
        }
      });

      console.log(DateTime.now() < DateTime.fromISO());
      if (hasVac) {
        this.$swal
          .fire({
            icon: "question",
            titleText:
              f.prenom +
              " " +
              f.nom +
              " a des vacations enregistrées sur la zone " +
              zv.nom +
              ".\nCelles-ci seront supprimées.\nSouhaitez-vous continuer ?",
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Oui`,
            // denyButtonText: `Non`,
            cancelButtonText: `Annuler`
          })
          .then(result => {
            if (result.isConfirmed) {
              axios
                .post(process.env.VUE_APP_API + "/vacation/delete", {
                  zone: zv.id,
                  fse: f.id
                })
                .then(() => {
                  this.$swal
                    .fire(
                      "Les vacations ont bien été supprimées.",
                      "",
                      "success"
                    )
                    .then(() => {
                      const index = zv.fses.indexOf(fse);
                      if (index > -1) {
                        zv.fses.splice(index, 1);
                      }
                    });
                })
                .catch(() => {
                  this.$swal.fire("Une erreur s'est produite.", "", "error");
                });
            } else if (result.isDenied) {
              console.log(result);
            }
          });
      } else {
        const index = zv.fses.indexOf(fse);
        if (index > -1) {
          zv.fses.splice(index, 1);
        }
      }
    },
    saveZonesVacations: function() {
      let v = JSON.parse(JSON.stringify(this.zonesVacations));
      v = v.map(zv => {
        delete zv.communes;
        delete zv.etablissement;
        zv.fses = zv.fses.map(f => {
          return { id: f.id };
        });
        return zv;
      });
      axios
        .post(process.env.VUE_APP_API + "/zone-vacation/save", v)
        .then(() => {
          this.$swal.fire(
            "Les vacations ont bien été enregistrées.",
            "",
            "success"
          );
        })
        .catch(() => {
          this.$swal.fire("Une erreur s'est produite.", "", "error");
        });
    },
    log: function() {
      console.log(this.zonesVacations);
    },
    showAlert(txt) {
      this.$swal.fire(txt).then();
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  h3 {
    margin-bottom: 1.5rem;

    img {
      margin-right: 1.5rem;
    }
  }

  h4 {
    background: var(--navy);
    border-radius: 0.5rem;
    color: white;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0 0 1rem;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
  }

  .col {
    padding-bottom: 2rem;

    @media screen and (min-width: 992px) {
      padding-bottom: 0;
      padding-left: 5rem;
    }
  }

  .zone-vacations {
    margin-bottom: 3.5rem;
  }

  .villes {
    background: var(--lightgrey);
    border-radius: 1rem;
    box-shadow: inset 0 4px 5px rgba(black, 0.3);
    font-size: 1.3rem;
    font-weight: 600;
    padding: 1.5rem;
    text-transform: uppercase;
  }

  .fse {
    flex-grow: 1;
    margin-bottom: 1rem;
  }

  .btn-add {
    background: var(--yellow);
    border-radius: 50%;
    height: 3.2rem;
    width: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
  }
}
</style>
